html,
body,
div,
span,
iframe,
map,
font,
img,
a img,
samp,
strong,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
b,
p,
blockquote,
a,
address,
code,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
table,
tr,
td,
th,
input {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 12px;
  background: #fff;
}

a {
  /* 小手 */
  cursor: pointer;
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

i,
em {
  font-style: normal;
}

input {
  background: none;
}

.clearFix:after {
  content: ".";
  height: 0;
  visibility: hidden;
  display: block;
  clear: both;
}

body {
  min-width: 1440px;
  /* 字体兼容 */
  /* font-family: -apple-system, BlinkMacSystemFont, "SF Pro SC", "SF Pro Text", "Helvetica Neue", Helvetica, "PingFang SC", "Segoe UI", Roboto, "Hiragino Sans GB", 'arial', 'microsoft yahei ui', "Microsoft YaHei", SimSun, sans-serif;
  -webkit-font-smoothing: antialiased; */
  background: #fff;
}
